document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  // Helper function to check if the user is on a mobile device
  function isMobile() {
    return window.innerWidth <= 992; // Adjust the breakpoint as needed
  }

  // Horizontal Scroll Animation
  const featureHorizonalScroll = gsap.utils.toArray(
    ".o-home-feature__contentblock"
  );
  if (featureHorizonalScroll.length) {
    gsap.to(featureHorizonalScroll, {
      xPercent: -100 * (featureHorizonalScroll.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".js-feature-transition",
        pin: true,
        scrub: 1,
        snap: 1 / (featureHorizonalScroll.length - 1),
        end: () =>
          "+=" + document.querySelector(".js-feature-transition").offsetWidth,
        pinSpacing: true,
      },
    });
  }

  // Vertical Scroll Animation
  let textElementCount = 0;
  const howContainer = document.querySelector(".js-vertical-wrap-main");
  const serviceImg = document.querySelectorAll(".js-vertical-image > li");
  const animation = gsap.utils.toArray(".js-vertical-image > li");
  const textPart = gsap.utils.toArray(".js-vertical-text > li");

  if (howContainer && !isMobile()) {
    // Disable vertical scroll animation on mobile
    let previousBottomPoint = 0;

    // Calculate the dynamic end value for the pinning
    const containerHeight = howContainer.scrollHeight + 100;
    // const pinDuration = containerHeight + window.innerHeight;

    gsap.timeline({
      scrollTrigger: {
        id: "MAIN",
        trigger: howContainer,
        start: "top top",
        end: `+=${containerHeight}px`,
        pin: true,
      },
    });

    function serviceImgInitialState() {
      serviceImg.forEach((element, index) => {
        const translateX = 10 - 90 * index;
        const scale = 1 - 0.2 * index;
        if (index < 2) {
          element.style.transform = `translate(${translateX}px, ${0}px) scale(${scale})`;
          element.style.backgroundColor = "white";
          element.style.zIndex = `${serviceImg.length - index}`;
          element.style.opacity = index === 0 ? 1 : 0.5;
        } else {
          element.style.transform = `translate(${translateX}px, ${0}px) scale(${scale})`;
          element.style.backgroundColor = "white";
          element.style.zIndex = `${serviceImg.length - index}`;
          element.style.opacity = 0;
        }
      });
    }

    function updatePositions(hiddenIndex) {
      let counter = 0;
      animation[hiddenIndex].style.opactiy = 0;
      visibleElements = animation.length - hiddenIndex - 1;

      animation.forEach((el, index) => {
        const valueResetter = index - (hiddenIndex + 1);
        if (index > hiddenIndex && counter < 2) {
          gsap.to(el, {
            x: 10 - 90 * valueResetter,
            scale: 1 - 0.2 * valueResetter,
            opacity: counter === 0 ? 1 : 0.5,
            duration: 0.1,
            ease: "power2.out",
          });
          counter++;
        } else if (index > hiddenIndex) {
          gsap.to(el, {
            x: 10 - 90 * valueResetter,
            scale: 1 - 0.2 * valueResetter,
            opacity: 0,
            duration: 0.1,
            ease: "power2.out",
          });
        }
      });
    }

    function revealTextBody(textElement) {
      textPart.forEach((element, index) => {
        gsap.to(element, {
          opacity: 0.2,
          overflow: "hidden",
          duration: 0.2,
          ease: "power2.in",
        });
      });
      gsap.to(textElement, {
        opacity: 1,
        overflow: "visible",
        duration: 0.5,
        ease: "power2.out",
      });
    }

    function hideTextBody() {
      textPart.forEach((el) => {
        gsap.to(el, {
          opacity: 0.2,
          overflow: "hidden",
          duration: 0.2,
          ease: "power2.in",
        });
      });
    }

    function exitPosition(hiddenIndex) {
      const toBeMount = [...animation].slice(hiddenIndex);
      toBeMount.forEach((el, index) => {
        if (index < 2) {
          gsap.to(el, {
            x: 10 - 90 * index,
            scale: 1 - 0.2 * index,
            duration: 0.1,
            opacity: 1,
            ease: "power2.out",
          });
        } else {
          el.style.opacity = 0;
        }
      });
    }

    serviceImgInitialState();

    function textMovementUpdater(textElement) {
      const elemetIndex = textPart.indexOf(textElement);

      if (textPart.length - textElementCount > 2) {
        const previousElement = textPart[elemetIndex - 1]; // previous elemet;
        if (elemetIndex !== 0) {
          hideTextBody(previousElement);
        }
        const offHeight = textElement.offsetHeight;
        gsap.to(textElement, {
          y: -offHeight,
          opacity: 0,
          marginTop: -offHeight,
        });
      }
    }

    textPart.forEach((textElement, index) => {
      const imageElement = animation[index];
      const triggerPointTop =
        previousBottomPoint === 0 ? 200 : previousBottomPoint;
      const triggerPointBottom = triggerPointTop + 100;
      previousBottomPoint = triggerPointBottom + 100;

      if (index === 0) {
        revealTextBody(textElement);
      } else {
        hideTextBody(textElement);
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          id: `pen-${index}`,
          trigger: imageElement,
          start: `${triggerPointTop}px 250px`,
          end: `${triggerPointBottom}px 100px`,
          scrub: true,

          onLeave: ({ direction }) => {
            console.log("LEAVE DIRECTION", direction);
            if (direction === 1) {
              updatePositions(index);
              if (index !== animation.length - 1) {
                textMovementUpdater(textElement);
              }
              textElementCount =
                textElementCount !== textPart.length - 1
                  ? textElementCount + 1
                  : textPart.length - 1;
            }
          },
          onEnterBack: ({ direction }) => {
            console.log("ENTER BACK DIRECTION", direction);
            if (direction === -1) {
              exitPosition(index);
              revealTextBody(textElement);
              gsap.to(textElement, {
                y: 0,
                opacity: 1,
                marginTop: 0,
              });
              textElementCount =
                textElementCount === 0 ? 0 : textElementCount - 1;
            }
          },
          onLeaveBack: ({ direction }) => {
            console.log("LEAVE BACK DIRECTION", direction);
            if (index !== 0 && direction === -1) {
              hideTextBody(textElement);
            }
          },
        },
      });

      tl.add(
        gsap.fromTo(
          textElement,
          { opacity: textElement === textPart[0] ? 1 : 0.9 },
          {
            onStart: () => {
              revealTextBody(textElement);
            },
          }
        )
      );

      tl.add(
        gsap.to(imageElement, {
          scale: 1.1,
          opacity: 1,
          x: 30,
          // opacity:animation.length - 1 === index ? 1 : 0,
          display: animation.length - 1 === index ? "block" : "none",
          ease: "power1.out",
          pin: animation.length - 1 === index ? true : false,
        })
      );
    });
  }
});
